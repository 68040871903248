.whatsappCircle {
    position: fixed;
    bottom: 15px;
    right: 30px;
    display: flex; /* Faz com que o conteúdo do círculo seja exibido como um flex container */
    justify-content: center; /* Centraliza horizontalmente */
    align-items: center; /* Centraliza verticalmente */
    
  }
  
  .whatsappIcon {    
    position: relative;
    color: #1cdb62; /* Cor do ícone do WhatsApp */

  }


.navbar {
    transition: transform 1s ease, background-color 1s ease;
    padding: 10px;
}

.scrolled {
    background-color: #FFFFFF;
}

.instagram-icon {
    color: #43160b;
    font-size: 42px;
    transition: transform 1s ease, background-color 1s ease;
}

.liEstilizado {
    font-weight: bolder;
    padding: 2vh;
    background-color: #773a2b;
    display: block;
    /* max-width: 40%; */
}

.ancora {
    font-weight: bolder;
    padding: 2vh;
    background-color: #773a2b;
    display: block;
    /* max-width: 40%; */
}

.liEstilizado a {
    color: #43160b
}

.ancoraColor {
    color: #43160b
}

.social-links {
    display: none;
    position: fixed;
    right: 0px;
    z-index: 9999;
}

.navbar-toggler {
    border-color: #43160b;
}

.ulEstilizado {
    direction: column;
}

@media (max-width:300px) {
    .brandSize img {
        width: 40vw;
    }
}

/* Tamanho de tela maior que 576px */
@media (min-width:300px) and (max-width: 576px) {
    .brandSize img {
        width: 60vw;
    }
}

/* Tamanho de tela maior que 992px */
@media (min-width: 992px) {
    .brandSize img {
        height: 100px;
    }   
}

/* Tamanho de tela maior que 1200px */
@media (min-width: 1200px) {
    .ulEstilizado {
        flex-direction: row;
        position: relative;
    }

    .liEstilizado {
        display: inline-block;
        background-color: inherit;
    }

    .ancora {
        background-color: #43160b;
        border-radius: 5vh;
        display: inline-block;
        font-weight: bolder;
        padding: 2vh 4vh;
    }

    .ancoraColor {
        color: #FFFFFF;
        opacity: 75%;
    }

    .ancoraColor:hover {
        color: #FFFFFF;
        opacity: 100%;
    }
}
.faixaMarron {
    background-color: #43160B;
    padding-top: 1rem;
}

.fraseBanner {
    font-size: clamp(0.95rem, 1.2vw, 1.5rem);
    color: #FFFFFF;
    text-align: justify;
    margin-top: 150px;
}

@media (max-width: 991px){
    .fraseBanner{
        margin-top: 15px;
        margin-bottom: 20px;;
    }
    .faixaMarron {
        height: max-content;
    }

    .imagemBrulee {
        width: 38vh;
    }
}

@media (min-width: 992px) {
    .faixaMarron {
        height: 280px;
    }

    .fraseBanner {
        margin-top: 90px;
    }
    .imagemBrulee {
        height: 70%;
    }
}
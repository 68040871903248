.espacoAbaixo{
    margin-bottom: 50px;
    

}
.cContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }

  
  .cLine {
    flex-grow: 1;
    height: 3px;
    background-color: #43160b;
  }
  
  .cText {
    color: #43160b;
    font-weight: bold;
  }

  .cTitulo{
    color: #43160b;
    font-weight: bolder;
    font-size: clamp(1rem, 2.5vw, 1.2rem);
  }
  .cTexto{
    text-align: center;
    color: #43160b;
    font-size: clamp(0.82rem, 1.1vw, 1.5rem);
  }

.badge-color{
  background-color: #43160b;
  margin-bottom: 4%;
}

.carousel-inner{
  height: 350px;
}
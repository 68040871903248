.footerContainer{
    width: 100%;
    color: #43160B;
    text-align: center;


}

.footerText1{
    font-size: clamp(1rem, 2.5vh, 5rem);
    font-weight: bolder;
}
.footerText2{
    font-size: clamp(0.8rem, 2.2vh, 4.8rem);
}
.footerIcon{
    font-size: clamp(1rem, 2.5vh, 5rem);
    font-weight: bolder;
    margin: 0px;
}

.footerIcon a {
    color: #43160B;
    text-decoration: none;
}

.qsTitulo {
    color: #43160B;
    font-weight: bolder;
    text-align: center;
}

.qsTexto {
    font-size: 18px;
    text-align: justify;
}


.imagemSign {
    align-items: 'center';
    width: 90%;
}

.imagemCaixa{
    width: 100%
}

@media (max-width: 992px){
    .qsTitulo {
        font-size: 8vw;
        margin-top: 4vw;
    }
    .qsTexto{
        margin-top: 4vw;
    }
}

@media (min-width: 992px){
    .qsTitulo {
        font-size: 4vw;
        margin-top: 4vw;
    }
    .qsTexto{
        margin-top: 2vw;
    }
}

@media (min-width: 1600px){
    .qsTitulo {
        margin-top: 0.2vw;
    }
}

@media (min-width: 2000px){
    .qsTitulo {
        margin-top: 0px;
    }

}
.politicaDePrivacidade-container {
    width: 100%;
    height: 100vh;
    padding: 8rem clamp(1.5rem, 4vh, 4rem)
    
}

.politicaDePrivacidade-container h1 {
    font-size: 17px;
    color: #43160b;
    font-weight: bolder;
    text-align: justify;
}

.politicaDePrivacidade-container p{
    font-size: 17px;
    text-align: justify;
}
/* backgroundRotativo.css */
.background-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: opacity 0.5s ease-in-out;
}

.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}

/* Tamanho de tela até 576px */
@media (max-width: 576px) {
  .textoPersonalizado {
    margin-top: 30vh;
  }
}

/* Tamanho de tela maior que 576 */
@media (min-width: 576px) {
  .textoPersonalizado {
    margin-top: 40vh;
  }
}

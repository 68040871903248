.contatoContainer {
    background-color: #43160B;
    padding: 5% 0%;
}

@media (max-width: 576px) {
    .whatsappIcon {
        font-size: 28px;
        font-weight: bolder;
        /* margin-right: 1rem; */
    }
    .contatoText{
        font-size: 14px;   
    }
    .whatsappTitulo{
        font-size: 28px;
        font-weight: bolder;       
    }    
    .whatsappNumero{
        font-size: 16px;
        font-weight: bolder;
    }
}

@media (min-width: 576px) {
    .whatsappIcon {
        font-size: 48px;
        font-weight: bolder;
        /* margin-right: 1rem; */
    }
    .contatoText{
        font-size: 26px;   
    }
    .whatsappTitulo{
        font-size: 48px;
        font-weight: bolder;       
    }    
    .whatsappNumero{
        font-size: 28px;
        font-weight: bolder;
    }
}

@media (min-width: 992px) {
    .whatsappIcon {
        font-size: 48px;
        font-weight: bolder;
        margin-right: 1rem;

    }
    .contatoText{
        font-size: 24px;   
    }
    .whatsappTitulo{
        font-size: 48px;
        font-weight: bolder;
       
    }    
    .whatsappNumero{
        font-size: 26px;
        font-weight: bolder;
    }
}

@media (min-width: 1200px) {
    .whatsappIcon {
        font-size: 72px;
        font-weight: bolder;
        margin-right: 1rem;

    }
    .contatoText{
        font-size: 36px;   
    }
    .whatsappTitulo{
        font-size: 72px;
        font-weight: bolder;
     
    }    
    .whatsappNumero{
        font-size: 38px;
        font-weight: bolder;
    }
}

@media (min-width: 1700px) {
    .whatsappIcon {
        font-size: 92px;
        font-weight: bolder;
        margin-right: 1rem;

    }
    .contatoText{
        font-size: 48px;   
    }
    .whatsappTitulo{
        font-size: 92px;
        font-weight: bolder;
   
    }    
    .whatsappNumero{
        font-size: 52px;
        font-weight: bolder;
    }
}